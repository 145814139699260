import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FetchDoctorCouponsList } from "../../../domain/usages/doctors/fetch-doctor-coupons";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchDoctorCouponsList implements FetchDoctorCouponsList {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(params: FetchDoctorCouponsList.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
