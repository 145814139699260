import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FetchSlab } from "../../../domain/usages/discount-approvals/fetch-slab";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";
import Swal from "sweetalert2";

export class RemoteFetchSlab implements FetchSlab {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(params: FetchSlab.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    } else if (
      httpResponse.status == HTTPStatusCode.FORBIDDEN ||
      httpResponse.status == HTTPStatusCode.UNAUTHORIZED
    ) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  }
}
