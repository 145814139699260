import React from "react";
import { CouponPage } from "../presentation/pages/CouponPage";
import { DoctorPage } from "../presentation/pages/DoctorPage";
import { LoginPage } from "../presentation/pages/LoginPage";
import DiscountApprovalsPage from "../presentation/pages/DiscountApprovalsPage";
import { PageRoutes } from "./PageRoutes";
import AuthenticationPage from "../presentation/pages/AuthenticationPage";
import ProductSlabPage from "../presentation/pages/ProductSlabPage";

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: PageRoutes.AUTHENTICATE,
    name: "Authenticate Page",
    component: AuthenticationPage,
  } as AppRoute,
  {
    path: PageRoutes.LOGIN,
    name: "Login Page",
    component: LoginPage,
  } as AppRoute,
  {
    path: PageRoutes.COUPON_PAGE,
    name: "Coupon Page",
    component: CouponPage,
  } as AppRoute,
  {
    path: PageRoutes.DOCTOR_PAGE,
    name: "Doctor Page",
    component: DoctorPage,
  } as AppRoute,
  {
    path: PageRoutes.DISCOUNT_APPROVALS_PAGE,
    name: "Discount Approvals Page",
    component: DiscountApprovalsPage,
  } as AppRoute,
  {
    path: PageRoutes.PRODUCT_SLAB,
    name: "Product Slab Page",
    component: ProductSlabPage,
  } as AppRoute,
];

export default indexRoutes;
