import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  MenuItem,
  TablePagination,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useDiscountApprovalStore } from "../../store/main/DiscountApprovalStore";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../routes/PageRoutes";
import { debounce } from "lodash";
import { GridSearchIcon } from "@mui/x-data-grid";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const ProductSlabPage = () => {
  const { FetchSlabDetails, slabList } = useDiscountApprovalStore();
  const [selectedAppCode, setSelectedAppCode] = useState("cpay");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      let q: string = "";
      let app_code: string = selectedAppCode;
      await FetchSlabDetails(q, app_code);
      setPageLoading(false);
    };

    fetchData();
  }, [selectedAppCode]);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PageRoutes.DISCOUNT_APPROVALS_PAGE);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAppCode(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSlabs = Array.isArray(slabList)
    ? slabList.filter((slab: any) => {
        const matchesSearch =
          searchTerm === "" ||
          slab.product_name.toLowerCase().includes(searchTerm) ||
          slab.brand_name.toLowerCase().includes(searchTerm);

        const matchesAppCode =
          selectedAppCode === "" || slab.app_code === selectedAppCode;

        return matchesSearch && matchesAppCode;
      })
    : [];
  const debouncedOnChange = debounce(handleSearch, 1000);

  const paginatedSlabs = filteredSlabs?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      {slabList.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Typography>No data found. Please refresh</Typography>
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Button onClick={handleNavigate}>
            <ArrowBackIcon sx={{ mr: 1 }} />
            Discount Approval
          </Button>
          <Typography variant="h5" gutterBottom>
            Product Slab Details
          </Typography>

          <Stack
            justifyContent={"space-between"}
            flexDirection={"row"}
            sx={{ display: "flex", gap: 2, mb: 2 }}
          >
            <Box>
              <TextField
                label="Search "
                variant="outlined"
                placeholder="Product or Brand"
                onChange={debouncedOnChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <GridSearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "30ch", mr: 1 }}
              />

              <TextField
                select
                label="App Code"
                value={selectedAppCode}
                onChange={handleDropdownChange}
                variant="outlined"
                sx={{ width: "20ch" }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="cpay">CPAY</MenuItem>
                <MenuItem value="cpaisa">CPAISA</MenuItem>
                <MenuItem value="cod">COD</MenuItem>
              </TextField>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <TablePagination
                component="div"
                count={filteredSlabs?.length ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[15]}
              />
            </Box>
          </Stack>
          {pageLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              {" "}
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="product slab table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      App
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Product
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Brand
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      UOM
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      MOQ
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      PTR
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Total Discount
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Trade Discount%
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Additional Discount
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Start Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      End Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedSlabs.length > 0 &&
                    paginatedSlabs.map((slab) => (
                      <TableRow key={slab.id}>
                        <TableCell
                          sx={{ color: "#454545", textTransform: "uppercase" }}
                          align="center"
                        >
                          {slab.app_code}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.product_name}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.brand_name}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.uom_name}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.min_quantity}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.ptr}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.max_discount}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.trade_discount}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {slab.max_discount - slab.trade_discount}
                        </TableCell>
                        <TableCell sx={{ color: "#454545" }} align="center">
                          {moment(new Date(slab.start_date)).format("ll")}
                        </TableCell>
                        <TableCell align="center">
                          {slab.end_date
                            ? moment(new Date(slab.end_date)).format("ll")
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductSlabPage;
