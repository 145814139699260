import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { FetchCouponCustomersList } from "../../../domain/usages/coupons/fetch-coupon-customers";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchCouponCustomersList
  implements FetchCouponCustomersList
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    id: string,
    params?: FetchCouponCustomersList.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
