import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDiscountApprovalStore } from "../../../../store/main/DiscountApprovalStore";
import { FetchEntityType } from "../../../../domain/models/discount-approvals/fetch-entity-type";
import DiscountApprovalsProductsTable from "./DiscountApprovalsProductsTable";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  selectClasses,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { toTitleCase } from "../../../../domain/Utils";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

interface RowProps {
  row: FetchEntityType;
  rowIndex: number;
  isOpen: boolean;
  setOpenRow: (rowIndex: number) => void;
}

const searchBoxStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "12px",
    color: "primary.light",
  },
  borderRadius: "10px",
  backgroundColor: "white",
  marginBottom: 1,
};

const Row: React.FC<RowProps> = ({ row, rowIndex, isOpen, setOpenRow }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleOpenProductDetails = () => {
    setOpenRow(rowIndex);
  };

  const applySearchFilter = (e: any) => {
    couponRequestForCustomeFilters.q = e.target.value;
    fetchCouponRequestForCustomer(row.customer_id, 1, 10);
  };
  const debouncedOnChange = debounce(applySearchFilter, 1000);

  const {
    fetchCouponRequestForCustomerState,
    couponRequestForCustomerList,
    lastCouponRequestForCustomerPage,
    currentCouponRequestForCustomerPage,
    fetchCouponRequestForCustomer,
    handlePageNumberCouponRequestForCustomer,
    couponRequestForCustomeFilters,
    entityListFilters,
    fetchEntityList,
  } = useDiscountApprovalStore();

  const handlePageChange = (event: any, newPage: number) => {
    handlePageNumberCouponRequestForCustomer(newPage);
    fetchCouponRequestForCustomer(row.customer_id, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value));
    fetchCouponRequestForCustomer(
      row.customer_id,
      currentCouponRequestForCustomerPage,
      parseInt(event.target.value)
    );
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={handleOpenProductDetails}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenProductDetails}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{toTitleCase(row.customer_name)}</TableCell>
        <TableCell component="th" scope="row">
          {row.entity_name ? toTitleCase(row.entity_name) : "-"}
        </TableCell>
        <TableCell align="left">{toTitleCase(row.org_code)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={debouncedOnChange}
                  size="small"
                  placeholder="Search Product..."
                  sx={searchBoxStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {<SearchIcon />}
                      </InputAdornment>
                    ),
                  }}
                />

                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  marginBottom={1}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography variant="caption">Rows per page:</Typography>
                    <FormControl>
                      <Select
                        value={rowsPerPage.toString()}
                        variant="standard"
                        size="small"
                        onChange={handleChangeRowsPerPage}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={70}>70</MenuItem>
                        <MenuItem value={90}>90</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Pagination
                    count={lastCouponRequestForCustomerPage}
                    variant="outlined"
                    shape="rounded"
                    page={currentCouponRequestForCustomerPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </Stack>
              {fetchCouponRequestForCustomerState === FetchState.SUCCESS &&
                couponRequestForCustomerList.length > 0 && (
                  <Typography variant="caption" fontWeight={660}>
                    No of Pending Products:{" "}
                    {couponRequestForCustomerList.length}
                  </Typography>
                )}
              <TableContainer sx={{ height: "40vh", overflow: "auto" }}>
                <Table size="small" stickyHeader>
                  {fetchCouponRequestForCustomerState ===
                    FetchState.LOADING && (
                    <Stack spacing={2}>
                      <Skeleton
                        width={"100%"}
                        height={30}
                        variant="rectangular"
                      />
                      <Skeleton
                        width={"100%"}
                        height={30}
                        variant="rectangular"
                      />
                      <Skeleton
                        width={"100%"}
                        height={30}
                        variant="rectangular"
                      />
                      <Skeleton
                        width={"100%"}
                        height={30}
                        variant="rectangular"
                      />
                    </Stack>
                  )}
                  {fetchCouponRequestForCustomerState === FetchState.SUCCESS &&
                    couponRequestForCustomerList.length === 0 && (
                      <Typography variant="body1" fontWeight={660}>
                        No Product Details Found
                      </Typography>
                    )}

                  {fetchCouponRequestForCustomerState === FetchState.SUCCESS &&
                    couponRequestForCustomerList.length > 0 && (
                      <DiscountApprovalsProductsTable customerData={row} />
                    )}
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
interface prop {
  selected: string;
}

const DiscountApprovalsEntitiesTable: React.FC<prop> = ({ selected }) => {
  const {
    entityList,
    fetchCouponRequestForCustomer,
    couponRequestForCustomeFilters,
  } = useDiscountApprovalStore();

  const [openRow, setOpenRow] = React.useState<number | null>(null);

  const handleOpenRow = (rowIndex: number) => {
    let temp = openRow;
    if (temp == rowIndex) {
      temp = null;
    } else {
      temp = rowIndex;
    }
    if (selected == "pending") {
      couponRequestForCustomeFilters.is_approval_required = "1";
      couponRequestForCustomeFilters.status = "";
    } else if (selected == "approved") {
      couponRequestForCustomeFilters.is_approval_required = "";
      couponRequestForCustomeFilters.status = "approved";
    } else if (selected == "all") {
      couponRequestForCustomeFilters.is_approval_required = "";
      couponRequestForCustomeFilters.status = "";
    }
    couponRequestForCustomeFilters.q = "";
    setOpenRow(temp);
    if (temp === rowIndex) {
      fetchCouponRequestForCustomer(entityList[rowIndex].customer_id, 1, 10);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ height: "75vh", overflow: "auto" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#C7CED5" }} />

            <TableCell align="left" sx={{ backgroundColor: "#C7CED5" }}>
              <Typography variant="subtitle2" fontWeight={660}>
                Customer
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{ backgroundColor: "#C7CED5 !important" }}
            >
              <Typography variant="subtitle2" fontWeight={660}>
                Entity
              </Typography>
            </TableCell>
            <TableCell align="left" sx={{ backgroundColor: "#C7CED5" }}>
              <Typography variant="subtitle2" fontWeight={660}>
                Organisation
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entityList.map((row, index) => (
            <Row
              key={index}
              row={row}
              rowIndex={index}
              isOpen={openRow === index}
              setOpenRow={handleOpenRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DiscountApprovalsEntitiesTable;
