import {
  Box,
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDiscountApprovalStore } from "../../../../store/main/DiscountApprovalStore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toTitleCase } from "../../../../domain/Utils";
import { FetchEntityType } from "../../../../domain/models/discount-approvals/fetch-entity-type";
import EditIcon from "@mui/icons-material/Edit";

import moment from "moment";


import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
interface Action {
  productId: number;
  actionValue: string;
}
type Props = {
  customerData: FetchEntityType;
};

const DiscountApprovalsProductsTable: React.FC<Props> = ({ customerData }) => {
  const { couponRequestForCustomerList, handleConfirmModal } =
    useDiscountApprovalStore();

  const [role, setRole] = useState("");
  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      try {
        const payload = JSON.parse(atob(authToken.split(".")[1]));
        if (payload && payload.role) {
          setRole(payload.role);
          console.log(payload.role);
        } else {
          console.error("Role information not found in token payload");
        }
      } catch (error) {
        console.error("Invalid token format or decoding error", error);
      }
    } else {
      console.error("No auth_token found in localStorage");
    }
  }, []);

  return (
    <>
      <TableHead sx={{ backgroundColor: "#C7CED5" }}>
        <TableRow>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              {" "}
              Product
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Organisation
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              MOQ
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Discount As Per Slab
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>

              Discount (Requested -{">"} Approved)
            </Typography>
          </TableCell>

          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Start Date (Requested -{">"} Approved)
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              End Date (Requested -{">"} Approved)
            </Typography>
          </TableCell>
          {couponRequestForCustomerList.map(
            (customer) => customer.status == "approved" && <></>
          )}


          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Actions
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {couponRequestForCustomerList.map((couponRequest, index: number) => (
          <TableRow key={index}>
            <TableCell align="center" component="th" scope="row">
              {couponRequest.variant_name}
            </TableCell>
            <TableCell align="center">
              {toTitleCase(couponRequest.org_code)}
            </TableCell>
            <TableCell align="center">{couponRequest.min_quantity}</TableCell>
            <TableCell align="center">
              {couponRequest.discount_as_per_slab}

            </TableCell>
            {couponRequest.status == "approved" ? (
              <TableCell align="center">
                <Typography display="inline" variant="body2" color={"GrayText"}>
                  {couponRequest.requested_discount}
                </Typography>
                {couponRequest.approved_discount && (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", margin: "0 4px" }}
                  />
                )}
                <Typography
                  display="inline"
                  variant="body2"
                  color={"green"}
                  fontWeight={600}
                >
                  {couponRequest.approved_discount}
                </Typography>
              </TableCell>
            ) : (
              <TableCell align="center" component="th" scope="row">
                {couponRequest.requested_discount}
              </TableCell>
            )}

            <TableCell align="center">
              {couponRequest.date == null
                ? "-"
                : moment(couponRequest.date).format("DD-MM-YYYY")}
              {couponRequest.status == "approved" && (
                <>
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", margin: "0 4px" }}
                  />

                  <Typography
                    display="inline"
                    variant="body2"
                    color={"green"}
                    fontWeight={600}
                  >
                    {couponRequest.approved_start_date == null
                      ? "-"
                      : moment(couponRequest.approved_start_date).format(
                          "DD-MM-YYYY"
                        )}
                  </Typography>
                </>
              )}

            </TableCell>
            {couponRequest.status == "approved" ? (
              <TableCell align="center">
                <Typography display="inline" variant="body2" color={"GrayText"}>
                  {couponRequest.requested_discount}
                </Typography>
                {couponRequest.approved_discount && (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", margin: "0 4px" }}
                  />
                )}
                <Typography
                  display="inline"
                  variant="body2"
                  color={"green"}
                  fontWeight={600}
                >
                  {couponRequest.approved_discount}
                </Typography>
              </TableCell>
            ) : (
              <TableCell align="center" component="th" scope="row">
                {couponRequest.requested_discount}
              </TableCell>
            )}

            <TableCell align="center">

              {couponRequest.end_date == null
                ? "-"
                : moment(couponRequest.end_date).format("DD-MM-YYYY")}
              {couponRequest.status == "approved" && (
                <>
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", margin: "0 4px" }}
                  />

                  <Typography
                    display="inline"
                    variant="body2"
                    color={"green"}
                    fontWeight={600}
                  >
                    {couponRequest.approved_end_date == null
                      ? "-"
                      : moment(couponRequest.approved_end_date).format(
                          "DD-MM-YYYY"
                        )}
                  </Typography>
                </>
              )}
            </TableCell>
            {/* <TableCell align="center">
              {couponRequest.approved_start_date == null
                ? "-"
                : moment(couponRequest.approved_start_date).format(
                    "DD-MM-YYYY"
                  )}
            </TableCell>

            <TableCell align="center">
              {couponRequest.approved_end_date == null
                ? "-"
                : moment(couponRequest.approved_end_date).format("DD-MM-YYYY")}
            </TableCell> */}
            <TableCell align="center">

              {(couponRequest.master_slab.app_code === "cpay" &&
                role === "ADMIN" &&
                couponRequest.status === "pending") ||
              (couponRequest.master_slab.app_code !== "cpay" &&
                couponRequest.status === "pending") ? (

                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Tooltip title="Click to approve requested discount">
                    <Button
                      onClick={() =>
                        handleConfirmModal(true, {
                          customerId: customerData.customer_id,
                          action: "approve",
                          discountAsPerSlab: couponRequest.discount_as_per_slab,
                          requestId: couponRequest.id,
                          productName: couponRequest.product_name,
                  approve_start_date: couponRequest.approved_start_date,
                          approve_end_date: couponRequest.approved_end_date,
                          start_date: couponRequest.date,
                          end_date: couponRequest.end_date,

                          MOQ: couponRequest.min_quantity,
                          selectedDiscount: couponRequest.requested_discount,
                        })
                      }
                    >
                      <CheckCircleIcon color="success" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Click to edit requested discount">
                    <Button
                      onClick={() =>
                        handleConfirmModal(true, {
                          customerId: customerData.customer_id,
                          action: "edit",
                          discountAsPerSlab: couponRequest.discount_as_per_slab,
                          requestId: couponRequest.id,
                          productName: couponRequest.product_name,

                          start_date: couponRequest.date,
                          end_date: couponRequest.end_date,

                          MOQ: couponRequest.min_quantity,
                          selectedDiscount: couponRequest.requested_discount,
                        })
                      }
                    >
                      <EditIcon color="warning" />
                    </Button>
                  </Tooltip>
                </Stack>
              ) : (
                <Tooltip title={couponRequest.remarks}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography

                      sx={{
                        textAlign: "center",
                        backgroundColor: "green",
                        color: "white",
                        padding: 0.8,
                        borderRadius: "10px",
                        textTransform: "capitalize",
                      }}
                      mr={1}
                    >
                      {couponRequest.is_approval_required == 0
                        ? "Auto Approved"
                        : couponRequest.status}

                    </Typography>
                    <InfoIcon />
                  </Box>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default DiscountApprovalsProductsTable;
