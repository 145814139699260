import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  COUPON_TOKEN_KEY,
  DISCOUNT_COUPON_API_URL,
} from "../../../base";
import { Endpoints } from "../../../domain/Endpoints";
import { RemoteFetchSlab } from "../../../data/usecases/discount-approvals/remote-fetch-slabs";
import {
  ProductSlab,
  ProductSlabsResponse,
} from "../../../domain/models/discount-approvals/fetch-slab";
import { get } from "lodash";

export type SlabFiltersType = {
  q: string;
  app_code: string;
};

interface FetchSlabDetailsSliceInterface {
  FetchSlabDetails: (q: string, app_code: string) => Promise<void>;
  slabList: ProductSlab[];
  FetchSlabDetailsState: FetchState;
}

const initialStates: Omit<FetchSlabDetailsSliceInterface, "FetchSlabDetails"> =
  {
    FetchSlabDetailsState: FetchState.DEFAULT,
    slabList: {} as ProductSlab[],
  };

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchSlabDetailsSlice: StateCreator<
  FetchSlabDetailsSliceInterface
> = (set) => ({
  ...initialStates,

  FetchSlabDetails: async (q: string, app_code: string) => {
    const token = storage.get(COUPON_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchSlab = new RemoteFetchSlab(
      `${DISCOUNT_COUPON_API_URL}${Endpoints.FETCH_SLAB_DETAILS}`,
      axiosHttpClient
    );
    try {
      let params: any = {};
      if (q?.length) {
        params = {
          q: q,
          app_code: app_code,
        };
      } else {
        params = {
          app_code: app_code,
        };
      }

      const result: ProductSlabsResponse = await remoteFetchSlab.fetch(params);

      if (result) {
        set(() => ({
          slabList: result.product_slabs,
          FetchSlabDetailsState: FetchState.SUCCESS,
        }));
      } else {
        set(() => ({ FetchSlabDetailsState: FetchState.ERROR }));
      }
    } catch (err) {
      console.error("Error fetching slab details:", err);
      set(() => ({ FetchSlabDetailsState: FetchState.ERROR }));
    }
  },
});
