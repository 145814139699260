import { create } from "zustand";
import { useFetchEntityListSlice } from "../slices/DiscountApprovals/FetchEntitySlice";
import { useFetchCouponRequestForCustomerSlice } from "../slices/DiscountApprovals/FetchCouponRequestForCustomerSlice";
import { useUpdateRequestStatusSlice } from "../slices/DiscountApprovals/UpdateRequestSlice";
import { useFetchSlabDetailsSlice } from "../slices/DiscountApprovals/FetchSlabDetailsSlice";

interface DiscountApprovalStoreType
  extends ReturnType<typeof useFetchEntityListSlice>,
    ReturnType<typeof useFetchCouponRequestForCustomerSlice>,
    ReturnType<typeof useUpdateRequestStatusSlice>,
    ReturnType<typeof useFetchSlabDetailsSlice> {}

export const useDiscountApprovalStore = create<DiscountApprovalStoreType>(
  (...coupon) => ({
    ...useFetchEntityListSlice(...coupon),
    ...useFetchCouponRequestForCustomerSlice(...coupon),
    ...useUpdateRequestStatusSlice(...coupon),
    ...useFetchSlabDetailsSlice(...coupon),
  })
);
