import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Swal from "sweetalert2";
import { screenNavigation } from "../../domain/Utils";
import { LocalLoggedInUser } from "../../data/usecases/auth/local-logged-in-user";

const AuthenticationPage = () => {
  const { authToken } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken && searchParams.get("type")) {
      loggedInUser.setCouponToken(authToken);
      screenNavigation(searchParams.get("type"))
        ? navigate(screenNavigation(searchParams.get("type")))
        : Swal.fire("Unknown Type", "", "error");
    } else {
      Swal.fire("Authentication Failed", "", "error");
    }
  }, [authToken, searchParams.get("type")]);

  return <div></div>;
};

export default AuthenticationPage;
