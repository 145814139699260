import HTTPStatusCode from "../../../domain/enums/httpStatusCode";
import { DeleteProducts } from "../../../domain/usages/coupons/delete-product";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpDeleteClient } from "../../protocols/http/http-delete-client";

export class RemoteDeleteProducts implements DeleteProducts {
  constructor(
    private readonly url: string,
    private readonly HttpDeleteClient: HttpDeleteClient
  ) {}

  async remove(id: string, params?: DeleteProducts.Params): Promise<any> {
    const httpResponse = await this.HttpDeleteClient.delete({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
